<template>
    <div class="login">
        <div class="form">
            <h1>Welcome back</h1>
            <p>Sign-in to continue</p>
          <form @submit.prevent="handleSubmit">
            <input type="email" name="email" id="email"  placeholder="Enter your email" v-model="email">
            <input type="password" name="password" id=""  placeholder="Enter your password" v-model="password">
            <p v-if="error" id="error">{{ error }}</p>
            <div id="left"><a href="/reset_password">Forgot Password?</a></div>
            <button>LOGIN</button>
            <p><a href="/signup"> Signup for free</a></p>
        </form>
        </div>
    </div>
</template>



<style scoped>

  div.login {
    text-align: center;
  }
  #left {
    text-align: left !important;
    font-size: 10px;
    margin-left: 20px;
  }
  input {
    width: 100%;
    height: 40px;
    border-radius: 30px;
    text-indent: 15px;
    background: #ffffff;
    transition: 0.3s;
    position: relative;
    border: 1px solid #cdd4e7;
    margin: 10px auto;
    outline: none;
  }
  :-ms-input-placeholder {
    color: gray;
  }
 .login button {
    padding: 2.5px 10px;
    border-radius: 2.5px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 30px;
    background: linear-gradient(90deg, #dc143c, #1f1f1f,#ff6f61);
    color: white !important;
    margin: 10px auto;
    border: 1px solid var(--brown);
    font-family: 'League Spartan', sans-serif;
  }

  a {
    color: #dc143c;
  }
  p {
    margin: 20px auto;
  }
  .form {
   padding: 25px 0;
   width: 90%;
    max-width: 350px;
    margin:10% auto;
    border-radius: 2.5px;
    }
    #error {
        color: rgb(206, 28, 28);
        font-size: 12px;
        font-weight: bold;
        background-color: rgb(249, 223, 223);
        box-sizing: border-box;
        padding: 5px;
        width: 100%;
        margin-left: auto;
        margin-right:auto ;
    }
</style>
<script>
export default {

  data() {
    return{
      email:'',
      password:'',
      error:''
    }
  },
  mounted() {
    console.log(this.$store.state.user)
  },
  methods: {
    async handleSubmit() {
      try {
        await this.$store.dispatch('login',{
          email:this.email,
          password:this.password
        })
        this.$router.push('/user/dashboard')
      } catch(err) {
        this.error = err.message
      }
    },
  }
}
</script>