

<script>
import { RouterView } from 'vue-router';
export default {
    components: {
      RouterView
    }
   }

</script>

<template>
  <RouterView />
 </template>

<style>
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
body {
  font-family:'Space Grotesk', sans-serif;
  margin: 0;
  width: 100%;
  background-color: #F5f5f0;
  background-image: url('/src/assets/desktop.svg');
  /* overflow-x: hidden; */
}
h1,h2,h3 {
  font-family:'Poppins', sans-serif !important;
}
.logo {
  width: 500px;
}
@media (max-width:700px) {
  body {
    background-image: url('/src/assets/mobile.svg')
  }
  
}

</style>
