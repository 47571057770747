import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '../views/HomePage.vue'
import LogIn from '../views/LogIn.vue'
import SignUp from '@/views/SignUp.vue'
import DashBoard from '@/views/DashBoard.vue'
import BuyCrypto from '@/views/BuyCrypto.vue'
import BuyGiftCards from '@/views/BuyGiftCards.vue'
import UserProfile from '@/views/UserProfile.vue'
import GiftTrans from '@/views/GiftTrans.vue'
import TotTrans from '@/views/TotTrans.vue'
import CryptoLog from  '@/views/CryptoLog.vue'
import TranSaction from '@/views/TranSaction.vue'
import AdminTrans from '@/views/AdminTrans.vue'
import ConfirmTrans from '@/views/ConfirmTrans.vue'
import SetTings from '@/views/SetTings.vue'
import ReSet from '@/views/ReSet.vue'


const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/login',
      name: 'login',
      component: LogIn
    },
    {
      path: '/signup',
      name: 'signup',
      component: SignUp
    },
    {
      path: '/user/dashboard',
      name: 'dashboard',
      component: DashBoard
    },
    {
      path: '/user/trade-crypto',
      name: 'BuyCrypto',
      component: BuyCrypto
    },
    {
    path: '/user/trade-giftcard',
    name: 'BuyGiftCards',
    component: BuyGiftCards
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: UserProfile
  },
  {
    path: '/user/giftcard-logs',
    name: 'GiftTrans',
    component: GiftTrans
  },
  {
    path: '/user/logs',
    name: 'TotTrans',
    component: TotTrans
  },
  {
    path: '/user/crypto-logs',
    name: 'CryptoLog',
    component: CryptoLog
  },
  {
    path: '/user/transaction-details/:id',
    name: 'TranSaction',
    component: TranSaction
  },
  {
    path: '/admin/pending',
    name: 'AdminTrans',
    component: AdminTrans
  },
  {
    path: '/admin/confirmed',
    name: 'ConfirmTrans',
    component: ConfirmTrans
  },
  {
    path: '/user/settings',
    name: 'SetTings',
    component: SetTings
  },
  {
    path: '/reset_password',
    name: 'ReSet',
    component: ReSet
  },
  ]
})

export default router
