<template>
    <div class="login">
        
        <div class="form">
            <h1>Create an account</h1>
            <p>Register to continue</p>
          <form @submit.prevent="handleSubmit">
            <input type="text" name="FirstName" id="First"  placeholder="Your Firstname" v-model="FirstName">
            <input type="text" name="LastName" id="last"  placeholder="Your Lastname" v-model="LastName">
            <input type="email" name="email" id="email"  placeholder="Enter your email" v-model="email">
            <input type="password" name="password" id="password"  placeholder="Enter your password" v-model="password">
            <input type="password" name="password" id="confirm" placeholder="Confirm your password" v-model="confirm">
            <!-- <p v-if="error" id="error">{{ error }}</p> -->
            <button>CREATE ACCOUNT</button>
            <p><a href="/login">Have an Account? Login</a></p>
        </form>
        </div>
    </div>
</template>


<style scoped>

  div.login {
    text-align: center;
  }
  
  input {
    width: 100%;
    height: 40px;
    border-radius: 30px;
    text-indent: 15px;
    background: #ffffff;
    transition: 0.3s;
    position: relative;
    border: 1px solid #cdd4e7;
    margin: 10px auto;
    outline: none;
  }
  :-ms-input-placeholder {
    color: gray;
  }
 .login button {
    padding: 2.5px 10px;
    border-radius: 2.5px;
    height: 40px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 30px;
    background: linear-gradient(90deg, #dc143c, #1f1f1f,#ff6f61);;
    color: white !important;
    margin: 10px auto;
    border: 1px solid var(--brown);
    font-family: 'League Spartan', sans-serif;
  }

  a {
    color:#dc143c;
  }
  p {
    margin: 20px auto;
  }
  .form {
    background-color: var(--green);
   padding: 25px 0;
   width: 90%;
    max-width: 350px;
    margin:10% auto;
    border-radius: 2.5px;
    }
    #error {
        color: rgb(206, 28, 28);
        font-size: 12px;
        font-weight: bold;
        background-color: rgb(249, 223, 223);
        box-sizing: border-box;
        padding: 5px;
        width: 80%;
        margin-left: auto;
        margin-right:auto ;
    }
</style>


<script>
 export default {
  data() {
    return{
      email:'',
      password:'',
      confirm:'',
      FirstName:'',
      LastName:'',
    }
  },
  mounted() {
    console.log(this.$store.state.user)
  },
  methods: {
    async handleSubmit() {
      if (this.confirm===this.password){
        const name = `${this.FirstName} ${this.LastName}`;
        try {
        await this.$store.dispatch('signup',{
          email:this.email,
          password:this.password,
          name: name,
        })
        this.$router.push('/user/dashboard')
      } catch(err) {
        this.error = err.message
      }
      }
      else {
        this.error = 'Password does not match'
      }
    },
  }
 }
</script>