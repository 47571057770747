<template>
  <div class="about">
    <div class="row">
      <img src="../assets/bt.png" alt="Platform Image" class="about-image" />

      <div class="about-content">
        <h4 class="about-heading">
          The most trustworthy cryptocurrency and giftcards exchange platform available. Where you can have quick access
          to your money whenever you desire.
        </h4>
      </div>
    </div>

    <div class="faq-section">
      <h2>Frequently Asked Questions</h2>

      <div class="faq-item">
        <button class="faq-button" @click="toggleFAQ('q1')">
          How do I trade Cryptocurrencies?
        </button>
        <p v-if="activeFAQ === 'q1'" class="faq-answer">
          1. Log into your Account <br />
          2. Copy our wallet address <br />
          3. Send Coins and upload proof <br />
          4. Provide your account for payment and confirm your transaction
        </p>
      </div>
      <div class="faq-item">
        <button class="faq-button" @click="toggleFAQ('q3')">
          How do I trade Giftcards?
        </button>
        <p v-if="activeFAQ === 'q3'" class="faq-answer">
          1. Log into your Account <br />
          2. Choose the card you wish to sell <br />
          3. Choose the country giftcard,Denomination and typpe(E-Code or physical)<br />
          4. Upload card photos <br />
          5. Provide your account for payment and confirm your transaction
        </p>
      </div>

      <div class="faq-item">
        <button class="faq-button" @click="toggleFAQ('q2')">
          When will I receive the money?
        </button>
        <p v-if="activeFAQ === 'q2'" class="faq-answer">
          Once the transaction is initiated and confirmed, funds are typically transferred within 25-30 minutes.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeFAQ: null
    };
  },
  methods: {
    toggleFAQ(question) {
      this.activeFAQ = this.activeFAQ === question ? null : question;
    }
  }
};
</script>

<style scoped>
.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-top: 60px;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.about-image {
  width: 100%;
  max-width: 400px;
  height: auto;
  border-radius: 20px;
  margin-bottom: 20px;
}

.about-content {
  max-width: 800px;
  text-align: center;
}

.about-heading {
  font-size: 1.5rem;
  margin-bottom: 30px;
  line-height: 1.6;
}

.faq-section {
  margin-top: 20px;
  text-align: left;
}

.faq-item {
  margin-bottom: 20px;
}

.faq-button {
  width: 100%;
  background-color: #b22222;
  color: white;
  padding: 10px 20px;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  text-align: left;
  border-radius: 5px;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.faq-button:hover {
  background-color: #d24d4d;
  /* Darker shade on hover */
  transform: scale(1.02);
}

.faq-answer {
  margin-top: 10px;
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about {
    padding: 15px;
  }

  .row {
    flex-direction: column;
  }

  .faq-button {
    font-size: 1rem;
    padding: 8px 16px;
  }

  .about-heading {
    font-size: 1.2rem;
  }
}
</style>