<template>
  <img src="../assets/logo.jpg" alt="" class="logo">
  <div class="crypto-trade" v-if="user">
    <h2>Trade GiftCards</h2>
    <GiftCardlist />
    <form @submit.prevent="Add(); sendEmail()">
      <label for="option">GiftCard Type </label>
      <select v-model="selectOption">
        <option disabled value="no">Select an Option</option>
        <option value="Physical">Physical</option>
        <option value="E-Code">E-Code</option>
      </select>
      <label for="category">Giftcard Name (Category)</label>
    <select id="category" v-model="category">
      <option disabled value="">Please select a category</option>
      <option v-for="(subcategory, category) in giftCards" :key="category" :value="category">
        {{ category }}
      </option>
    </select>
    <label for="subcategory">Giftcard SubCategory (Range)</label>
    <select id="subcategory" v-model="sub" :disabled="!subcategories.length">
      <option v-if="subcategories.length === 0">No subcategory available</option>
      <option v-for="subcategory in subcategories" :key="subcategory" :value="subcategory">
        {{ subcategory }}
      </option>
    </select>
      <label for="giftcard">Giftcard Country</label>
      <select name="country" id="giftcard" v-model="country" required>
        <option value="US">US</option>
        <option value="Canada">Canada</option>
        <option value="UK">UK</option>
        <option value="Hongkong">Hong Kong</option>
        <option value="Germany">Germany</option>
        <option value="New Zealand">New Zealand</option>
        <option value="Australia">Australia</option>
        <option value="Italy">Italy</option>
        <option value="Belgium">Belgium</option>
        <option value="Finland">Finland</option>
        <option value="Netherlands">Netherlands</option>
        <option value="Greece">Greece</option>
        <option value="Switzerland">Switzerland</option>
        <option value="Spain">Spain</option>
      </select>

      <label for="amount">Enter GiftCard Quantity </label>
      <input type="number" name="amount" id="amount" v-model="Amount" />
      <label for="amount">Preferred Rate (₦) </label>
      <input type="number" name="naira" id="naira" v-model="Rate" />
      <label for="amount">Total Amount (₦) </label>
      <input type="text" name="naira" id="naira_value" :value="'₦'+ Rate*Amount" disabled />
      <label for="proof">Upload Image(s) of GiftCard</label>
      <input type="file" name="proof" id="image" @change="Upload" required multiple accept="image/*"/>
      <label for="Bank_Name">Enter your Bank name</label>
      <input type="text" name="Bank_Name" id="Bank" v-model="Bank" required />
      <label for="Bank_Number">Enter your Bank Account Number</label>
      <input type="text" name="Bank_Number" id="Number" v-model="AcctNo" required />
      <label for="Name">Enter your Bank Account Name</label>
      <input type="text" name="Name" id="Name" v-model="AcctName" required />
      <div class="card">
        <h3>Summary</h3>
        <p>GiftCard Category: {{ category }}</p>
        <p>GiftCard Range: {{ sub }}</p>
        <p>GiftCard Country: {{ country }}</p>
        <p>Quantity: {{ Amount }}</p>
        <p>Rate: {{ Rate }}</p>
        <p>Amount(₦): {{ Rate * Amount }}</p>
        <p>Bank Name : {{ Bank }}</p>
        <p>Account Name :{{ AcctName }}</p>
        <p>Account Number :{{ AcctNo }}</p>
      </div>
      <button>{{ loading ? 'Loading........' : 'Confirm' }}</button>
    </form>
    <div class="nothing"></div>
    <NavBar />
  </div>
</template>

<style>
form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: baseline; */
}

.crypto-trade {
  width: 80%;
  margin: auto;
  max-width: 400px;
}

.crypto-trade input,
select {
  border: 0.5px solid white;
  border-radius: 20px;
  width: 100%;
  margin: 10px auto;
  outline: white;
  background-color: white !important;
  padding: 5px 10px;
  box-sizing: border-box;
}

.crypto-trade button {
  background-color: #dc3545 !important;
  color: white !important;
}

#image {
  height: 100px;
  border-radius: 10px;
}

.nothing {
  height: 200px;
}
</style>
<script>
import emailjs from "@emailjs/browser";
import NavBar from "../componets/NavBar.vue";
import GiftCardlist from "@/componets/GiftCardlist.vue";
export default {
  components: {
    NavBar,
    GiftCardlist,
  },
  data() {
    return {
      selectOption: '',
      category: '',
      sub: '',
      country: '',
      Amount: 0,
      Rate:0,
      Bank: '',
      AcctNo: '',
      AcctName: '',
      img: '',
      loading: false,
      giftCards: {
        'Amazon': ['100-200', '201-500', 'Others'],
        'Apple': ['25-99', '100-500', 'Others', 'Horizontal'],
        'Razer Gold': ['20-500', 'Others'],
        'American Express': [], // No subcategory
        'Steam': ['10-1000'],
        'Vanilla/One': [], // No subcategory
        'Vanilla VISA': [], // No subcategory
        'NordStorm': [], // No subcategory
        'Nike': [], // No subcategory
        'Macy': ['100-500', 'Others'],
        'Sephora': ['100-500'],
        'Target': ['100-500', 'Others'],
        'Ebay': ['100-200', 'Others'],
        'VISA': [], // No subcategory
        'Xbox': ['100-500', 'Others'],
        'Footlocker': ['100-500', 'Others'],
      },
    }
  },
  computed: {
    images() {
      return this.$store.state.images;
    },
    user() {
      return this.$store.state.user;
    },
    subcategories() {
      return this.category ? this.giftCards[this.category] : [];
    },
  },
  methods: {
    sendEmail() {
      emailjs.send("service_30j920q", "template_61l07py", {
        message: `A transaction has occurred at your account. Email: ${this.user.email}, Username: ${this.user.displayName}.\n \n Type:Giftcard. \n Category:${this.category}. \n Range: ${this.sub}. \n Rate: ${this.Rate}. \n Quantity: ${this.Amount} \n Transaction amount: ₦${this.Amount * this.Rate}`,
        to: this.user.email,
      }, "Vu8FgLbZEt6ecS1Nj")
        .then(() => {
         if(this.loading) {
          alert('Succesful! Redirecting....')
         }
        })
        .catch((error) => {
          console.log("Failed to send email:", error);
        });
    },

    Upload(e) { 
  const files = e.target.files;
  setTimeout(() => {
    console.log(this.img);
  }, 1000);
  
  if (files.length > 0) {
    this.img = Array.from(files); // Store all selected files in an array
  }
},
    async Add() {
      this.loading = true
      await Promise.all([
        this.$store.dispatch("upLoad", { Images: this.img })
      ]);
      await new Promise((resolve) => setTimeout(resolve, 4000));
      // Proceed immediately to dispatch AddGiftCard without any delay
      await this.$store.dispatch("AddGiftCard", {
        Type: 'GiftCard',
        GiftCard_Type: this.selectOption,
        Category: this.category,
        SubCategory: this.sub,
        Country: this.country,
        Amount: this.Amount,
        Rate: this.Rate,
        Naira_Amount: this.Rate * this.Amount,
        Bank: this.Bank,
        AccountName: this.AcctName,
        AccountNumber: this.AcctNo,
        Images:this.images,
        UserID: this.user.uid,
        Date: Date.now(),
        status: 'Pending'
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await this.$router.push('/user/logs')
    },

  },
  mounted() {
    if(this.user && this.user==null) {
      this.$router.push('/')
    }
  },
};
</script>
