<template>
    <div class="nav">
        <div @click="home">
            <svg @click="home" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                data-lucide="layout-dashboard" icon-name="layout-dashboard" class="lucide lucide-layout-dashboard">
                <rect width="7" height="9" x="3" y="3" rx="1"></rect>
                <rect width="7" height="5" x="14" y="3" rx="1"></rect>
                <rect width="7" height="9" x="14" y="12" rx="1"></rect>
                <rect width="7" height="5" x="3" y="16" rx="1"></rect>
            </svg>
        </div>
        <div @click=crypto> <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                data-lucide="download" icon-name="download" class="lucide lucide-download">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" x2="12" y1="15" y2="3"></line>
            </svg>
        </div>
        <div @click="his">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="gift"
                icon-name="gift" class="lucide lucide-gift">
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect width="20" height="5" x="2" y="7"></rect>
                <line x1="12" x2="12" y1="22" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
            </svg>
        </div>
        <div @click="profile"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                data-lucide="user" icon-name="user" class="lucide lucide-user">
                <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
            </svg>
        </div>
    </div>
</template>

<style>
.nav {
    background-color: #b22222;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0%;
    width: 100%;
    height: 50px;
    left: 0;

}

svg {
    color: white
}
</style>
<script>
export default {
    methods: {
        home() {
            this.$router.push('/user/dashboard')
        },
        crypto() {
            this.$router.push('/user/crypto-logs')
        },
        his() {
            this.$router.push('/user/giftcard-logs')
        },
        profile() {
            this.$router.push('/user/profile')
        },
    }
}
</script>
