// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage'
import {  getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import 'firebase/firestore';
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyALryqurlbKeDaZLH19o6Ty2WDFKo3iW0s",
  authDomain: "giftcard-9951a.firebaseapp.com",
  projectId: "giftcard-9951a",
  storageBucket: "giftcard-9951a.appspot.com",
  messagingSenderId: "553226549832",
  appId: "1:553226549832:web:655d68a886e97a0293767d",
  measurementId: "G-LHVTW9M2RL"
};

// Initialize Firebase
initializeApp(firebaseConfig);
export const auth = getAuth();
export const db = getFirestore();
export const storage = getStorage()