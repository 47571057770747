<template>
    <div class="quick">
        <RouterLink to="/user/trade-giftcard" class="none"><div class="giftcards">
            <div class="text">
              <div class="ff">
                <h5>Trade GiftCard</h5>
                <img src="../assets/arrow.png" alt="">
              </div>
              <p>Sell your giftcards at market  trading rates</p>
            </div>
            <img src="../assets/giftcard-removebg-preview.png" alt="">
        </div>
      </RouterLink>
      <RouterLink to="/user/trade-crypto"  class="none">
        <div class="btc">
            <div class="text">
              <div class="ff">
                <h5>Trade Crypto</h5>
                <img src="../assets/arrow.png" alt="">
              </div>
              <p>Sell your Crypto at market  trading rates</p>
            </div>
            <img src="../assets/btc.png" alt="">
        </div>
      </RouterLink>
    </div>
</template>

<style>
  .giftcards, .btc {
    width: 90%;
    padding: 20px;
    margin: 20px auto;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
  }
  .btc, .giftcards {
    background-color: white;
  }
  .none {
    text-decoration: none !important;
  }
  .quick img {
    width: 100px;
  }
  .ff {
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    width:80% ;
    max-width: 400px;
  }
  .ff img {
    width: 20px;
  }
</style>