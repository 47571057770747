<template>
   <div class="container">
    <div class="scroll">
        <div>
           <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/46/Bitcoin.svg/64px-Bitcoin.svg.png" alt="" class="bit">
           <p>Bitcoin</p>
        </div>
        <div>
          <img src="../assets/eth.png" alt="">
          <p>Ethereum</p>
        </div>
        <div>
          <img src="../assets/usdt1.png" alt="">
          <p>USDT(TRC20)</p>
        </div>
        <div>
          <img src="../assets/usdt2.png" alt="">
          <p>USDT(ERC20)</p>
        </div>
    </div>
   </div>
</template>

<style>
 .container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 30px
  }
  
  .scroll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
  }
  
  .scroll div {
    margin: 10px;
    text-align: center;
  }
  
  .scroll img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .container::-webkit-scrollbar {
    height: 8px;
  }
  
  .container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>