<template>
    <div class="settings-page">
      <h2>Settings</h2>
      <div class="settings-section">
        <label for="displayName">Enter Email</label>
        <input
          type="email"
          id="email"
          v-model="email"
          placeholder="Enter email address"
        />
        <button @click="sendEmail">Send Reset Email</button>
      </div>
    </div>
</template>

<script>
 export default {
    data() {
        return {
            email:'',
        }
    },
    methods: {
        sendEmail() {
            if(this.email) {
                this.$store.dispatch('sendLink', this.email)
            }
        },
    }
 }
</script>
<style scoped>
  .settings-page {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  .settings-section {
    margin-bottom: 30px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  .settings-page input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  .settings-page button {
    background: linear-gradient(90deg, #dc143c, #1f1f1f,#ff6f61);
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #d24d4d; /* Lighter Firebrick on hover */
  }
  
  @media (max-width: 768px) {
    .settings-page {
      padding: 15px;
    }
  
    button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
</style>