<template>
    <div class="giftcard-container">
      <div class="giftcard-scroll">
        <div class="giftcard-item" v-for="(card, index) in giftCards" :key="index">
          <img :src="card.image" :alt="card.name" />
          <p>{{ card.name }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        giftCards: [
          { name: 'Amazon', image: 'https://upload.wikimedia.org/wikipedia/commons/a/a9/Amazon_logo.svg' },
          { name: 'Apple', image: 'https://upload.wikimedia.org/wikipedia/commons/f/fa/Apple_logo_black.svg' },
          { name: 'Razer Gold', image: 'https://i.pinimg.com/736x/e4/67/0c/e4670c59dade6ed7522fa8054e687de3.jpg' }, // Updated link
          { name: 'American Express', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fa/American_Express_logo_%282018%29.svg/601px-American_Express_logo_%282018%29.svg.png' },
          { name: 'Steam', image: 'https://upload.wikimedia.org/wikipedia/commons/8/83/Steam_icon_logo.svg' },
          { name: 'Vanilla/One', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9e/Vanilla_Logo_1.png/800px-Vanilla_Logo_1.png?20150330072304' }, // Updated link
          { name: 'Vanilla Visa', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/512px-Visa_2021.svg.png' },
          { name: 'NordStorm', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a6/Nordstrom_Logo_1991.svg/531px-Nordstrom_Logo_1991.svg.png' },
          { name: 'Nike', image: 'https://upload.wikimedia.org/wikipedia/commons/a/a6/Logo_NIKE.svg' },
          { name: 'Macy', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a2/Macys_logo.svg/512px-Macys_logo.svg.png' },
          { name: 'Sephora', image: 'https://seeklogo.com/images/S/sephora-logo-F5C4DB9E97-seeklogo.com.png' },
          { name: 'Target', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/Target_logo.svg/432px-Target_logo.svg.png' },
          { name: 'EBAY', image: 'https://upload.wikimedia.org/wikipedia/commons/1/1b/EBay_logo.svg' },
          { name: 'VISA', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/d/d6/Visa_2021.svg/512px-Visa_2021.svg.png' },
          { name: 'Xbox', image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Xbox_Logo.svg/372px-Xbox_Logo.svg.png' },
          { name: 'Footlocker', image: 'https://upload.wikimedia.org/wikipedia/fr/thumb/9/94/Foot_Locker_logo.svg/339px-Foot_Locker_logo.svg.png?20190912154451' }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .giftcard-container {
    width: 100%;
    overflow-x: auto;
    margin-bottom: 30px
  }
  
  .giftcard-scroll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: max-content;
  }
  
  .giftcard-item {
    margin: 10px;
    text-align: center;
  }
  
  .giftcard-item img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-bottom: 10px;
  }
  
  .giftcard-container::-webkit-scrollbar {
    height: 8px;
  }
  
  .giftcard-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
  }
  
  .giftcard-container::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  </style>
  