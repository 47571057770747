<template>
    <img src="../assets/logo.jpg" alt="" class="logo">
    <main>
        <section>
        <div class="image">
            <img src="../assets/Hero_.png" alt="">
        </div>
        <h2>Trade your Giftcards and Cryptocurrencies</h2>
        <p>Trade your giftcards for cash or rewards at our store! Competitive rates, instant exchange and hassle-free
            process guaranteed</p>
        <button @click="move">Get started</button>
    </section>
    <AboutBar />
    </main>
    <FooterBar />
</template>

<style>
 section {
    text-align: center;
 }
 main {
    width: 80%;
    margin:10% auto;
 }
 section p {
    margin-bottom:50px ;
 }
 section button {
    color: white !important;
    border: 1px solid  white !important; 
    padding: 10px;
    background-color:  #b22222 !important;
    border-radius: 10px
 }
 .image img {
    width: 100%;
 }
 @media (min-width: 768px)  {
   .image img {
    width: 50%;
 }
 }
</style>
<script>
import FooterBar from '@/componets/FooterBar.vue';
import AboutBar from '@/componets/AboutBar.vue';
export default {
   components: {
      FooterBar,
      AboutBar,
   },
   computed: {
      user() {
      return this.$store.state.user;
    },
   },
   methods: {
      move() {
         if(this.user) {
            this.$router.push('/user/dashboard')
         }
         else {
            this.$router.push('/login')
         }
      }
   }
}
</script>