<template>
  <img src="../assets/logo.jpg" alt="" class="logo">
  <div class="crypto-trade" v-if="user">
    <h2>Trade Crypto</h2>
    <CryptoLister />
    <form @submit.prevent="Add(); sendEmail()">
      <label for="option">Choose Cryptocurrency </label>
      <select v-model="selectOption">
        <option disabled value="no">Select an Option</option>
        <option value="Bitcoin">Bitcoin</option>
        <option value="Ethereum">Ethereum</option>
        <option value="USDT(TRC20)">USDT(TRC20)</option>
        <option value="USDT(ERC20)">USDT(ERC20)</option>
      </select>
      <label for="amount">Amount {{ selectOption ? `of ${selectOption}` : ''}}($)</label>
      <input type="number" name="dollar" id="naira_value" v-model="CryptoUSD" >
      <label for="amount">Amount (₦)</label>
      <input type="text" name="naira_value" id="naira_value" :value="'₦' +   Math.round(CryptoUSD * Rate).toLocaleString('en-us')" disabled>
      <div class="wallet" v-if="selectOption">
         <div class="bitcoin" v-if="selectOption=='Bitcoin'">
            <h3>Send Bitcoin to our Bitcoin wallet</h3>
            <div class="address">
              <p>{{ btc }}</p>
              <button @click="Copy" class="copy">Copy</button>
            </div>
            <img src="../assets/qr.jpg" alt="">
         </div>

         <div class="bitcoin" v-else-if="selectOption=='USDT(TRC20)'">
            <h3>Send TRC20 to our TRC wallet</h3>
            <div class="address">
              <p>{{ trc }}</p>
              <button @click="Copy" class="copy">Copy</button>
            </div>
            <img src="../assets/trc.jpg" alt="">
         </div>
         <div class="bitcoin" v-else>
            <h3 v-if="selectOption=='Ethereum'">Send Ethereum to our Ethereum wallet</h3>
            <h3 v-else>Send ERC20 to our Ethereum Wallet </h3>
            <div class="address">
              <p>{{ eth }}</p>
              <button @click="Copy" class="copy">Copy</button>
            </div>
            <img src="../assets/ethqr.jpg" alt="">
         </div>
      </div>
      <label for="proof">Upload proof of payment</label>
      <input type="file" name="proof" id="proof" @change="Upload" required accept="image/*">
      <label for="Bank_Name">Enter your Bank name</label>
      <input type="text" name="Bank_Name" id="Bank" v-model="Bank" required>
      <label for="Bank_Number">Enter your Bank Account Number</label>
      <input type="text" name="Bank_Number" id="Number" v-model="AcctNo" required>
      <label for="Name">Enter your Bank Account Name</label>
      <input type="text" name="Name" id="Name" v-model='AcctName' required>

      <div class='card'>
        <h3>Summary</h3>
        <p>Crypto Name: {{ selectOption }} </p>
        <p>Naira Value: ₦{{  Math.round(CryptoUSD * Rate).toLocaleString('en-us') }}</p>
        <p>Dollar Value: ${{ CryptoUSD }}</p>
        <p>Bank Name : {{ Bank }} </p>
        <p>Account Name :{{ AcctName }} </p>
        <p>Account Number :{{ AcctNo }} </p>
      </div>
      <button>{{ loading ? 'Loading........' : 'Confirm' }}</button>
    </form>
    <div class="nothing"></div>
    <NavBar />
  </div>
</template>

<style>
form {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  /* align-items: baseline; */
}

.crypto-trade {
  width: 80%;
  margin: auto;
  max-width: 400px;
}

.crypto-trade input {
  border: 0.5px solid gray;
  width: 100%;
  margin: 20px auto;
  outline: white;
}

.crypto-trade button,
.butt button {
  background-image: linear-gradient(90deg, #dc143c, #1f1f1f, #ff6f61);
  color: white !important;
  padding: 2%;
}

#proof {
  height: 100px;
  border-radius: 10px;
}

.card {
  margin: 20px auto;
  width: 100%;
  padding: 10px 20px;
  background-color: #333333;
  color: white;
  border-radius: 20px;
}

.card p {
  margin: 10px;
}

#naira_value {
  background-color: rgb(248, 241, 241) !important;
  color: limegreen;
  font-weight: 600;
  font-size: 20px;
}
.copy {
  background-color: #dc143c !important;
  background-image: none !important;
  padding: 3px 10px !important;
  border-radius: 5px;
}
.wallet {
  margin-bottom: 50px;
  margin-top: 10px;
}
.bitcoin img {
  width: 80%;
  margin: 30px auto;
}
</style>

<script>
import emailjs from "@emailjs/browser";
import NavBar from "../componets/NavBar.vue"
import CryptoLister from "./CryptoLister.vue";
export default {
  components: {
    NavBar,
    CryptoLister
  },
  data() {
    return {
      selectOption: '',
      Bank: '',
      AcctNo: '',
      AcctName: '',
      proof: '',
      Rate:0,
      loading: false,
      CryptoUSD: 0,
      eth:'0x421116984062594Ea0be5262C225af9349680ccD',
      btc:'bc1qz5v4w07mhamag7wzp8zzx8ug8t390gjx9lau4k',
      trc:'TEJATrccZTpopzk8GSvMBZrLLqTfx5tkTv',
    }
  },
  computed: {
    proofUrl() {
      return this.$store.state.proof;
    },
    user() {
      return this.$store.state.user;
    },
  },
  methods: {
    Upload(e) {
      const file = e.target.files[0];
      setTimeout(() => {
        console.log(this.proof);
      }, 1000);
      if (file) {
        this.proof = file;
      }
    },
    Copy() {
     if (this.selectOption=='Bitcoin') {
      navigator.clipboard.writeText(this.btc)
                    .then(() => {
                        alert('Address copied: ' + this.btc);
                    })
                    .catch(err => {
                        alert('Failed to copy the address');
                        console.error('Could not copy text: ', err);
                    });
     }
     else if (this.selectOption=='USDT(TRC20)') {
      navigator.clipboard.writeText(this.trc)
                    .then(() => {
                        alert('Address copied: ' + this.trc);
                    })
                    .catch(err => {
                        alert('Failed to copy the address');
                        console.error('Could not copy text: ', err);
                    });
     }
     else {
      navigator.clipboard.writeText(this.eth)
                    .then(() => {
                        alert('Address copied: ' + this.eth);
                    })
                    .catch(err => {
                        alert('Failed to copy the address');
                        console.error('Could not copy text: ', err);
                    });
     }
    },
    async Add() {
      this.loading = true
      await this.$store.dispatch("upLoadProof", {
        Image: this.proof,
      });
      await new Promise((resolve) => setTimeout(resolve, 2000));
      await this.$store.dispatch("AddCrypto", {
        Type: 'Crypto',
        Name: this.selectOption,
        Naira_Amount:   Math.round(this.CryptoUSD * this.Rate).toLocaleString('en-us'),
        Dollar_Amount : this.CryptoUSD,
        Bank: this.Bank,
        AccountName: this.AcctName,
        AccountNumber: this.AcctNo,
        Image: this.proofUrl,
        UserID: this.user.uid,
        Date: Date.now(),
        status: 'Pending'

      });
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await this.$router.push('/user/logs')

    },
    sendEmail() {
      emailjs.send("service_30j920q","template_61l07py", {
        message: `A transaction has occurred at your account. Email: ${this.user.email}, Username: ${this.user.displayName}.\n \n Type:Crypto. \n  Name:${this.selectOption}.   \n Transaction Amount($): $${this.CryptoUSD} \n Transaction amount: ₦${this.CryptoUSD * this.Rate}`,
        to: this.user.email,
      }, "Vu8FgLbZEt6ecS1Nj")
        .then(() => {
          if(this.loading) {
          alert('Succesful! Redirecting....')
         }
        })
        .catch((error) => {
          alert("Failed to send email:", error);
        });
    },

    async convert() {
  try {
   
    // Fetch both USD and NGN rates concurrently
    const [ngnResponse] = await Promise.all([
      fetch(`https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=ngn`),
    ]);

    const ngnData = await ngnResponse.json();
  

     this.Rate = ngnData['tether'].ngn;
    // Calculate the amount in both NGN and USD

  } catch (error) {
    console.error('Error fetching crypto conversion:', error);
  }
}

    
  },
  mounted() {
    if(this.user && this.user==null) {
      this.$router.push('/')


    } 
    this.convert()
  },

  

}
</script>