<template>
    <footer class="footer">
      <div class="footer-content">
        <h3>Contact Us</h3>
        <ul class="footer-links">
          <li><a href="https://facebook.com" target="_blank">Facebook</a></li>
          <li><a href="https://instagram.com" target="_blank">Instagram</a></li>
          <li><a href="https://tiktok.com" target="_blank">TikTok</a></li>
          <li><a href="https://threads.net" target="_blank">Threads</a></li>
          <li><a href="https://wa.me/" target="_blank">WhatsApp</a></li>
          <li><a href="mailto:my.abtech81@gmail.com" target="_blank">Email</a></li>
          <li>Phone: 08054762216</li>
        </ul>
      </div>
      <div class="footer-bottom">
        <p>&copy; Abtech.com.ng {{ currentYear }}</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    data() {
      return {
        currentYear: new Date().getFullYear()
      };
    }
  };
  </script>
  
  <style scoped>
  /* Fix extra whitespace */
  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  .footer {
    background-color: #d24d4d; /* reddish-carton color */
    padding: 40px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
  }
  
  .footer-content {
    margin-bottom: 20px;
  }
  
  .footer h3 {
    margin-bottom: 20px;
    font-size: 1.5rem;
    letter-spacing: 1px;
  }
  
  .footer-links {
    list-style: none;
    padding: 0;
    margin: 0; /* Remove extra margin */
    display: flex;
    flex-direction: column; /* Change to column layout */
    align-items: center;
  }
  
  .footer-links li {
    margin: 10px 0; /* Space between links */
  }
  
  .footer-links a {
    text-decoration: none;
    font-size: 1.2rem;
    transition: color 0.3s ease, transform 0.3s ease;
  }
  
  .footer-links a:hover {
    color: #f0e68c; /* Light golden color on hover */
    transform: scale(1.1); /* Slight zoom effect */
  }
  
  .footer-bottom {
    margin-top: 20px;
    font-size: 1rem;
    opacity: 0.8;
  }
  
  @media (max-width: 768px) {
    .footer {
      padding: 30px 10px;
    }
  
    .footer h3 {
      font-size: 1.2rem;
    }
  
    .footer-links a {
      font-size: 1rem;
    }
  }
  </style>
  