<template>
    <img src="../assets/logo.jpg" alt="" class="logo">
    <div class="settings-page">
      <h2>Settings</h2>
  
      <!-- Update Display Name -->
      <div class="settings-section">
        <label for="displayName">Update Display Name</label>
        <input
          type="text"
          id="displayName"
          v-model="displayName"
          placeholder="Enter new display name"
        />
        <button @click="updateDisplayName">Update Display Name</button>
      </div>
  
      <!-- Update Password -->
      <div class="settings-section">
        <label for="password">Update Password</label>
        <input
          type="password"
          id="password"
          v-model="password"
          placeholder="Enter new password"
        />
        <button @click="updatePassword">Update Password</button>
      </div>
    </div>
    <FooterBar />
  </template>
  
  <script>
  import FooterBar from '@/componets/FooterBar.vue';
  export default {
    components : {
        FooterBar,
    },
    data() {
      return {
        displayName: '',
        password: ''
      };
    },
    methods: {
      async updateDisplayName() {
        if (this.displayName) {
        this.$store.dispatch('username_update',{
          name:this.displayName,
        })
        alert('DisplayName Updated');
        this.displayName = '';
        } else {
          alert("Please enter a valid display name.");
        }
      },
      updatePassword() {
        if (this.password) {
            this.$store.dispatch('change',this.password)
          alert("Password updated successfully!");
          this.password = ''; // Clear the input after updating
        } else {
          alert("Please enter a valid password.");
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .settings-page {
    max-width: 600px;
    margin: 0 auto;
    margin-bottom: 40px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .settings-section {
    margin-bottom: 30px;
  }
  
  label {
    display: block;
    margin-bottom: 10px;
    font-size: 1.2rem;
  }
  
  input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
  }
  
  button {
    background-color: #B22222; /* Firebrick color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #d24d4d; /* Lighter Firebrick on hover */
  }
  
  @media (max-width: 768px) {
    .settings-page {
      padding: 15px;
    }
  
    button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  </style>
  